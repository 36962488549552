import axios from 'axios'
import router from '@/router/index'
import {Message} from 'element-ui';
import config from '@/config'
import store from '@/store'
import Vue from 'vue'
import {getUserInfo, removeUserInfo} from "@/utils/cookies";

const baseUrl = process.env.NODE_ENV === 'development' ? config.baseUrl.dev : config.baseUrl.pro;

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common['Platform'] = "web";
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.delete['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;

axios.interceptors.request.use(config => {
    console.log(getUserInfo().token);
    config.headers['Authorization'] = getUserInfo().token;
    console.log(config);
    return config;
}, error => {
    return Promise.reject(error);
});


axios.interceptors.response.use(response => {
    console.log(response);
    if (response.data.code === 0) {
        if (response.data.message) {
            Message.success(response.data.message);
        }
        return response;
    } else if (response.data.code === 3) { // 登录过期
        if(router.currentRoute.path !== '/login') {
            Message.warning(response.data.message);
            removeUserInfo();
            router.push('/login');
        }
        return Promise.reject(new Error(response.data.message))
    } else { // 其他错误
        Message.warning(response.data.message);
        return Promise.reject(new Error(response.data.message))
    }
}, error => {
    console.log(error);
    return Promise.reject(error);
});

axios.upload = (url, file) => {
    let formData = new FormData();
    formData.append("file", file, file.name);
    let config = {
        headers: {"Content-Type": "multipart/form-data"}
    }
    return axios.post(url, formData, config)
}
//获取其他资源
axios.getUrl = (uri) => {
    return baseUrl + uri;
}

//获取教材资源
axios.getBookUrl = (uri) => {
    return baseUrl + "/book/" + uri;
}
Vue.prototype.$axios = axios;

export default axios;

import axios from "@/libs/axios";
export default {
    namespaced: true,
    state: {
        province: {
            list: [],
        },
        city: {
            list: [],
        },
        area: {
            list: [],
        },
        school: {
            list: [],
            total: 0
        },
    },
    mutations: {
        SET_PROVINCE_LIST: (state, list) => {
            state.province.list = list
        },
        CLEAR_PROVINCE_LIST: (state) => {
            state.province.list = [];
        },
        SET_SCHOOL_TOTAL: (state, total) => {
            state.school.total = total
        },
        SET_CITY_LIST: (state, list) => {
            state.city.list = list
        },
        SET_AREA_LIST: (state, list) => {
            state.area.list = list
        },
        SET_SCHOOL_LIST: (state, list) => {
            state.school.list = list
        },
    },
    actions: {
        // 获取省份信息列表
        provinceList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/province',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_PROVINCE_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取城市信息列表
        cityList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/city',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_CITY_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取区县信息列表
        areaList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/area',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_AREA_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取学校信息列表
        schoolList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/school/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_SCHOOL_LIST', response.data.data.schoolList);
                        commit('SET_SCHOOL_TOTAL', response.data.data.count);

                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //修改学校信息
        updateSchool({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/school',
                        method: 'put',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //删除学校
        deleteSchool({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/school',
                        method: 'delete',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //添加学校
        addSchool({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/school',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取权限信息列表
        generateFile({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/excel',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //文件生成
        downloadFile({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/excel/progress',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //上传文件
        uploadFile({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/school/excel/upload',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
    },

}

import axios from "@/libs/axios";
export default {
    namespaced: true,
    state: {
        semester: {
            list: [],
        },
        book: {
            list: [],
            total: 0
        },
        unit:{
            list: [],
            total: 0
        },
        chapter:{
            list: [],
            total: 0
        },
        unitName:{
            list: [],
        },
        bookName:{
            list: [],
        },
        word:{
            list: [],
            total: 0
        },
        chapterName:{
            list: [],
        },
        data16:[],

    },
    mutations: {
        SET_DATA16: (state, data) => {
            state.data16 = data;
        },
        SET_CHAPTERNAME_LIST: (state, list) => {
            state.chapterName.list = list
        },
        SET_WORD_TOTAL: (state, total) => {
            state.word.total = total
        },
        SET_WORD_LIST: (state, list) => {
            state.word.list = list
        },
        SET_SEMESTER_LIST: (state, list) => {
            state.semester.list = list
        },
        SET_BOOK_TOTAL: (state, total) => {
            state.book.total = total
        },
        SET_BOOK_LIST: (state, list) => {
            state.book.list = list
        },
        SET_BOOKNAME_LIST: (state, list) => {
            state.bookName.list = list
        },
        SET_UNIT_TOTAL: (state, total) => {
            state.unit.total = total
        },
        SET_UNIT_LIST: (state, list) => {
            state.unit.list = list
        },
        SET_UNITNAME_LIST: (state, list) => {
            state.unitName.list = list
        },
        SET_CHAPTER_TOTAL: (state, total) => {
            state.chapter.total = total
        },
        SET_CHAPTER_LIST: (state, list) => {
            state.chapter.list = list
        },
    },
    actions: {
        getData16({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/chapter/wrong/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA16', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取学期信息列表
        semesterList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/semester',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_SEMESTER_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取教材列表
        bookList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/book/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_BOOK_LIST', response.data.data.bookList);
                        commit('SET_BOOK_TOTAL', response.data.data.count);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //添加教材
        addBook({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/book',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //修改教材信息
        updateBook({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/book',
                        method: 'put',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //删除教材
        deleteBook({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/book',
                        method: 'delete',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取教材名称列表
        bookNameList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/book/name/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_BOOKNAME_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取单元列表
        unitList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/unit/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_UNIT_LIST', response.data.data.unitList);
                        commit('SET_UNIT_TOTAL', response.data.data.count);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //添加单元
        addUnit({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/unit',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //删除单元
        deleteUnit({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/unit',
                        method: 'delete',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //修改单元信息
        updateUnit({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/unit',
                        method: 'put',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取单元名称列表
        unitNameList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/unit/name/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_UNITNAME_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取章节列表
        chapterList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/chapter/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_CHAPTER_LIST', response.data.data.chapterList);
                        commit('SET_CHAPTER_TOTAL', response.data.data.count);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //添加章节
        addChapter({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/chapter',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //删除章节
        deleteChapter({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/chapter',
                        method: 'delete',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //修改章节信息
        updateChapter({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/chapter',
                        method: 'put',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取教材名称列表
        chapterNameList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/chapter/name/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_CHAPTERNAME_LIST', response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取字词列表
        wordList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/word/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_WORD_LIST', response.data.data.wordList);
                        commit('SET_WORD_TOTAL', response.data.data.count);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //添加字词
        addWord({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/word',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //删除字词
        deleteWord({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/word',
                        method: 'delete',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //修改字词信息
        updateWord({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/word',
                        method: 'put',
                        data: JSON.stringify(params),
                        // params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取权限信息列表
        generateFile({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/word/excel',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //文件生成
        downloadFile({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/word/excel/progress',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
    },

}

<template>
    <section class="app-main">
        <transition name="fade-transform" mode="out-in">
            <router-view :key="key"/>
        </transition>
        <div v-if="isSafari" style="width: 100%; height: 20px"></div>
    </section>
</template>

<script>
    export default {
        name: 'AppMain',
        data() {
            return {
                isSafari: false
            }
        },
        computed: {
            key() {
                return this.$route.path
            }
        },
        mounted() {
            this.isSafari = (navigator.userAgent.toLowerCase().indexOf("safari") !== -1 && navigator.userAgent.toLowerCase().indexOf("Mac") !== -1)
        },
    }
</script>

<style scoped>
    .app-main {
        /*90 = navbar  */
        min-height: calc(100vh - 20px);
        width: 100%;
        padding: 10px 20px 10px 20px;
        position: relative;
        overflow: hidden;
        background-color: #F0F2F5;
    }

    .fixed-header + .app-main {
        padding-top: 95px;
    }
</style>

<style lang="scss">
    // fix css style bug in open el-dialog
    .el-popup-parent--hidden {
        .fixed-header {
            padding-right: 15px;
        }
    }
</style>

import axios from "@/libs/axios";
export default {
    namespaced: true,
    state: {
        data1:[],
        data2:[],
        data3:[],
        data4:[],
        data5:[],
        data6:[],
        data7:[],
        data8:[],
        data9:[],
        data10:[],
        data11:[],
        data12:[],
        data13:[],
        data14:[],
        data15:[],
    },
    mutations: {
        SET_DATA1: (state, data) => {
            state.data1 = data;
        },
        SET_DATA2: (state, data) => {
            state.data2 = data;
        },
        SET_DATA3: (state, data) => {
            state.data3 = data;
        },
        SET_DATA4: (state, data) => {
            state.data4 = data;
        },
        SET_DATA5: (state, data) => {
            state.data5 = data;
        },
        SET_DATA6: (state, data) => {
            state.data6 = data;
        },
        SET_DATA7: (state, data) => {
            state.data7 = data;
        },
        SET_DATA8: (state, data) => {
            state.data8 = data;
        },
        SET_DATA9: (state, data) => {
            state.data9 = data;
        },
        SET_DATA10: (state, data) => {
            state.data10 = data;
        },
        SET_DATA11: (state, data) => {
            state.data11 = data;
        },
        SET_DATA12: (state, data) => {
            state.data12 = data;
        },
        SET_DATA13: (state, data) => {
            state.data13 = data;
        },
        SET_DATA14: (state, data) => {
            state.data14 = data;
        },
        SET_DATA15: (state, data) => {
            state.data15 = data;
        },
    },
    actions: {
        getData1({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/user/open/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA1', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData2({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/user/distributed/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA2', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData3({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/user/homework/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA3', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData4({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/user/exercise/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA4', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData5({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/user/group/open/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA5', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData6({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/new/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA6', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData7({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA7', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData8({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/creator/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA8', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData9({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/grade/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA9', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData10({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/grade/homework/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA10', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData11({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/assign/teacher/count/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA11', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData12({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/assign/time/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA12', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData13({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/group/assign/identity/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA13', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData14({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/book/distributed/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA14', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        getData15({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/statistics/book/use/wrong/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DATA15', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },

    }
}

import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import {getUserInfo} from '@/utils/cookies' // get token from cookie
import defaultSettings from '@/config'

Vue.use(Router);

NProgress.configure({showSpinner: false}); // 禁用环形进度条

const getPageTitle = (pageTitle) => {
    if (pageTitle) {
        return `${pageTitle} - ${defaultSettings.title}`
    }
    return `${defaultSettings.title}`
};

const createRouter = () => new Router({
    routes,
    // mode: 'history', // require service support
    base: process.env.BASE_URL,
    scrollBehavior: () => ({y: 0}),
});

const router = createRouter();

const whiteList = ['/login']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
        // start progress bar
        NProgress.start();

        // set page title
        document.title = getPageTitle(to.meta.title);

        // determine whether the user has logged in
        const hasToken = getUserInfo().token;
        if (hasToken) {
            if (to.path === '/login') {
                // if is logged in, redirect to the home page
                next({path: '/'});
            } else {
                next()
            }
        } else {
            /* has no token*/
            if (whiteList.indexOf(to.path) !== -1) {
                // in the free login whitelist, go directly
                next()
            } else {
                // other pages that do not have permission to access are redirected to the login page.
                next(`/login?redirect=${to.path}`);
            }
        }
        NProgress.done();
    }
);

router.afterEach(() => {
    NProgress.done() // finish progress bar
});

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

// 解决重复跳转同一个路由时的 NavigationDuplicated 问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};

export default router


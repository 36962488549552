import axios from "@/libs/axios"
import {setUserInfo, removeUserInfo} from '@/utils/cookies'
import {resetRouter} from '@/router'
import md5 from 'js-md5';

// 对于模块内部的 mutation 和 getter，接收的第一个参数是模块的局部状态对象。
// 对于模块内部的 action，局部状态通过 context.state 暴露出来，根节点状态则为 context.rootState
// 对于模块内部的 getter，根节点状态会作为第三个参数暴露出来：
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        // 登录
        login({state, commit}, {account, password}) {
            return new Promise((resolve, reject) => {
                account = account.trim();
                    password = md5(password.trim());
                    axios.request({
                        url: '/v1/web/user/login',
                        method: 'post',
                        data: {account, password}
                    }).then(response => {
                        setUserInfo(response.data.data);
                        resolve(response.data);
                    }).catch(() => {
                        reject()
                    });
                }
            );
        },
        // 退出登录
        logout({state, commit}) {
            removeUserInfo();
            resetRouter();
        },

        // remove token
        resetToken({commit}) {
            return new Promise(resolve => {
                removeUserInfo();
                resolve();
            })
        }
    }
}

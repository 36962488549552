import axios from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        user: {
            list: [],
            total: 0
        },
        permission:{
            list: [],
        },
        audit:{
            list: [],
            total: 0
        },
        detail:{
            book1:{},
            book2:{},
            unit1:{},
            unit2:{},
            chapter1:{},
            chapter2:{},
            word1:{},
            word2:{},
        }
    },
    mutations: {
        SET_USER_LIST: (state, list) => {
            state.user.list = list
        },
        SET_AUDIT_LIST: (state, list) => {
            state.audit.list = list
        },
        SET_AUDIT_TOTAL: (state, total) => {
            state.audit.total = total
        },
        SET_USER_TOTAL: (state, total) => {
            state.user.total = total
        },
        SET_PERMISSION_LIST: (state, list) => {
            state.permission.list = list
        },
        SET_DETAIL_BOOK1: (state, list) => {
            state.detail.book1 = list
        },
        SET_DETAIL_BOOK2: (state, list) => {
            state.detail.book2 = list
        },
        SET_DETAIL_UNIT1: (state, list) => {
            state.detail.unit1 = list
        },
        SET_DETAIL_UNIT2: (state, list) => {
            state.detail.unit2 = list
        },
        SET_DETAIL_CHAPTER1: (state, list) => {
            state.detail.chapter1 = list
        },
        SET_DETAIL_CHAPTER2: (state, list) => {
            state.detail.chapter2 = list
        },
        SET_DETAIL_WORD1: (state, list) => {
            state.detail.word1 = list
        },
        SET_DETAIL_WORD2: (state, list) => {
            state.detail.word2 = list
        },
    },
    actions: {
        // 获取管理员信息列表
        userList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/user/getUserList',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_USER_LIST', response.data.data.adminList);
                        commit('SET_USER_TOTAL', response.data.data.count);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //修改管理员信息
        updateUser({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/user/user',
                        method: 'put',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //删除管理员
        deleteUser({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/user/user',
                        method: 'delete',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        //添加管理员
        addUser({state, commit},params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/user/user',
                        method: 'post',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        // commit('SET_USER_TOTAL', response.data.data);
                        console.log('123456');
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取权限信息列表
        permissionList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/user/permission',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_PERMISSION_LIST', response.data.data);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取审核信息列表
        auditList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/audit/list',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_AUDIT_LIST', response.data.data.auditList);
                        commit('SET_AUDIT_TOTAL', response.data.data.count);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 获取审核详情
        detailList({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/audit',
                        method: 'get',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        commit('SET_DETAIL_BOOK1', response.data.data.book1);
                        commit('SET_DETAIL_BOOK2', response.data.data.book2);
                        commit('SET_DETAIL_UNIT1', response.data.data.unit1);
                        commit('SET_DETAIL_UNIT2', response.data.data.unit2);
                        commit('SET_DETAIL_CHAPTER1', response.data.data.chapter1);
                        commit('SET_DETAIL_CHAPTER2', response.data.data.chapter2);
                        commit('SET_DETAIL_WORD1', response.data.data.word1);
                        commit('SET_DETAIL_WORD2', response.data.data.word2);
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },
        // 审核
        audit({state, commit}, params) {
            return new Promise(resolve => {
                    axios.request({
                        url: '/v1/web/audit',
                        method: 'put',
                        data: JSON.stringify(params),
                        params
                    }).then(response => {
                        resolve(response.data.data);
                    }).catch(() => {
                    });
                }
            );
        },









    },

}

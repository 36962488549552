export default {
    /**
     * @description token在Cookie中存储的天数，默认1天
     */
    cookieExpires: 365,
    /**
     * @description 是否使用国际化，默认为false
     *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
     *              用来在菜单中显示文字
     */
    // useI18n: false,
    /**
     * @description api请求基础路径
     */
    baseUrl: {
        dev: '/api',
        pro: 'https://51tbx.com'
    },
    /**
     * @description 默认打开的首页的路由name值，默认为home
     */
    homeName: 'home',

    title: '报听写',

    loginTitle: '报听写后台管理系统',

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: true,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: true
}

<template>
    <div :class="classObj" class="app-wrapper">
        <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
        <sidebar class="sidebar-container"/>
        <div class="main-container">
            <div :class="{'fixed-header':fixedHeader}">
                <div class="title-container">
                    <span class="title">报听写管理后台</span>
                    <ul class="operation">
                        <li>
                            <span>欢迎您 {{getName()}}</span>
                        </li>
<!--                        <li>-->
<!--                            <el-button size="small" type="primary" plain @click="changePassword">修改密码</el-button>-->
<!--                        </li>-->
                        <li>
                            <el-button size="small" type="primary" plain @click="logout">退出</el-button>
                        </li>
                    </ul>
                </div>
                <navbar/>
            </div>
            <app-main/>
        </div>
        <el-dialog
                title="修改密码"
                :visible.sync="dialogVisible"
                width="600px">
            <div class="edit-container">
                <div class="row-container">
                    <div class="right-align"><span class="red">*</span>新密码：
                    </div>
                    <el-input class="input" type="password" v-model="password" placeholder="请输入新密码"></el-input>
                </div>
                <!--                <span class="password-tip">密码长度不小于6位，至少包含大写字母、小写字母、数字、符号中的3种</span>-->
                <div class="row-container">
                    <div class="right-align"><span class="red">*</span>新密码确认：
                    </div>
                    <el-input class="input" type="password" v-model="confirmPassword"
                              placeholder="请再次输入新密码"></el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmButton" style="margin-left: 50px">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {Navbar, Sidebar, AppMain} from './components'
    import ResizeMixin from './mixin/ResizeHandler'
    import {getUserInfo} from '@/utils/cookies'
    import md5 from 'js-md5'

    export default {
        name: 'Layout',
        data() {
            return {
                dialogVisible: false,
                password: '',
                confirmPassword: '',
                infoForm: {}
            }
        },
        components: {
            Navbar,
            Sidebar,
            AppMain
        },
        mixins: [ResizeMixin],
        mounted() {
            // this.$store.dispatch('app/getFilePath');
            // this.$store.dispatch('app/s3FileUpload');
            this.infoForm = getUserInfo();
        },
        computed: {
            sidebar() {
                return this.$store.state.app.sidebar
            },
            device() {
                return this.$store.state.app.device
            },
            fixedHeader() {
                return this.$store.state.app.fixedHeader
            },
            classObj() {
                return {
                    hideSidebar: !this.sidebar.opened,
                    openSidebar: this.sidebar.opened,
                    withoutAnimation: this.sidebar.withoutAnimation,
                    mobile: this.device === 'mobile'
                }
            }
        },
        methods: {
            handleClickOutside() {
                this.$store.dispatch('app/closeSideBar', {withoutAnimation: false})

            },
            logout() {
                this.$confirm('确认要退出系统吗?', '确认', {
                    confirmButtonText: '退出',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    await this.$store.dispatch('login/logout');
                    this.$router.push(`/login?redirect=${this.$route.fullPath}`);
                    this.$message({
                        type: 'success',
                        message: '注销成功!'
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            changePassword() {
                this.dialogVisible = true;
            },
            getName() {
                return getUserInfo().username;
            },
            confirmButton() {
                if (this.password !== this.confirmPassword) {
                    this.$message.warning('两次密码输入不一致');
                } else if (!this.password) {
                    this.$message.warning('密码不能为空');
                } else if (this.password.length < 6) {
                    this.$message.warning("密码长度不足6位");
                } else {
                    this.infoForm.password = md5(this.password);
                    this.$store.dispatch('user/adminUpdate', this.infoForm).then(() => {
                        this.$message.success('密码修改成功');
                        this.dialogVisible = false;
                    });
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~@/styles/mixin.scss";
    @import "~@/styles/variables.scss";

    .app-wrapper {
        @include clearfix;
        position: relative;
        height: 100%;
        width: 100%;

        &.mobile.openSidebar {
            position: fixed;
            top: 0;
        }
    }

    .drawer-bg {
        background: #000;
        opacity: 0.3;
        width: 100%;
        top: 0;
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    .title-container {
        height: 40px;
        background: #fff;

        .title {
            float: left;
            line-height: 40px;
            font-size: 24px;
            padding: 0 15px;
        }

        .operation {
            float: right;
            padding: 0;
            margin: 0;
            height: 40px;
            line-height: 40px;

            li {
                margin: 0 5px;
                display: inline-block;
                list-style: none;

            }
        }
    }

    .fixed-header {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 9;
        width: calc(100% - #{$sideBarWidth});
        transition: width 0.28s;
    }

    .hideSidebar .fixed-header {
        width: calc(100% - 54px)
    }

    .mobile .fixed-header {
        width: 100%;
    }

    .edit-container {

        text-align: center;

        .title {
            margin-bottom: 50px;
        }

        .row-container {
            padding: 8px 0;
            font-size: 14px;

            .right-align {
                display: inline-block;
                text-align: right;
                width: 110px;
                height: 30px;
                line-height: 30px;
            }

            .red {
                color: red;
                padding: 5px;
            }

            .text {
                padding-left: 25px;
            }

            .input {
                display: inline-block;
                width: 300px;
                padding-left: 10px;
            }
        }

        .password-tip {
            margin-left: 185px;
            padding: 5px;
            width: 315px;
            text-align: left;
            color: #aaaaaa;
            display: block;
        }

        .action-container {
            margin-top: 30px;
            margin-left: 130px;
        }

        .save {
            padding: 12px 28px;
        }

        .cancel {
            margin-left: 20px;
            padding: 12px 28px;
        }

    }

    .dialog-footer {
        display: block;
        text-align: center;
    }

</style>

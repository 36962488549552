import Cookies from 'js-cookie'
import defaultSettings from '@/config'

import Client from 'ali-oss'
// import AWS from 'aws-sdk' // 该库较大，谨慎使用
import SparkMD5 from 'spark-md5'
import axios from "../../libs/axios"
import {getData, setData} from "../../utils/cookies"

const {fixedHeader, sidebarLogo} = defaultSettings;

export default {
    namespaced: true,
    state: {
        sidebar: {
            opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
            withoutAnimation: false
        },
        device: 'desktop',
        fixedHeader: fixedHeader,
        sidebarLogo: sidebarLogo
    },
    getters: {
        sidebar: state => state.sidebar,
        device: state => state.device,
    },
    mutations: {
        TOGGLE_SIDEBAR: state => {
            state.sidebar.opened = !state.sidebar.opened;
            state.sidebar.withoutAnimation = false;
            if (state.sidebar.opened) {
                Cookies.set('sidebarStatus', 1)
            } else {
                Cookies.set('sidebarStatus', 0)
            }
        },
        CLOSE_SIDEBAR: (state, withoutAnimation) => {
            Cookies.set('sidebarStatus', 0);
            state.sidebar.opened = false;
            state.sidebar.withoutAnimation = withoutAnimation
        },
        TOGGLE_DEVICE: (state, device) => {
            state.device = device
        },
        CHANGE_SETTING: (state, {key, value}) => {
            if (state.hasOwnProperty(key)) {
                state[key] = value
            }
        }
    },
    actions: {
        toggleSideBar({commit}) {
            commit('TOGGLE_SIDEBAR')
        },
        closeSideBar({commit}, {withoutAnimation}) {
            commit('CLOSE_SIDEBAR', withoutAnimation)
        },
        toggleDevice({commit}, device) {
            commit('TOGGLE_DEVICE', device)
        },
        changeSetting({commit}, data) {
            commit('CHANGE_SETTING', data)
        },

        // 获取OSS授权信息，并上传文件。 file：需要上传的文件  stsUrl：获取sts信息的地址   return:文件地址
        OSSFileUpload({state, commit}, {file, stsUrl}) {
            if (!file || !stsUrl) {
                return
            }
            return new Promise((resolve) => {
                let fileReader = new FileReader();
                let spark = new SparkMD5(); // 创建md5（基于SparkMD5）
                if (file.size > 1024 * 1024 * 10) {
                    let data = file.slice(0, 1024 * 1024 * 10); //将文件进行分块 file.slice(start,length)
                    fileReader.readAsBinaryString(data); //将文件读取为二进制码
                } else {
                    fileReader.readAsBinaryString(file);
                }

                fileReader.onload = (e) => {
                    spark.appendBinary(e.target.result);
                    let md5 = spark.end();
                    resolve(md5 + file.name);
                };
            }).then(filename => {
                return new Promise(resolve => {
                        let stsInfo = getData("sts");

                        // 过期时间大于三分钟时直接读取缓存的授权信息
                        if (stsInfo && new Date(stsInfo.expiration).getTime() - 3 * 60 * 1000 > Date.now()) {
                            resolve(stsInfo)
                        } else {
                            axios.request({
                                url: stsUrl,
                                method: 'get',
                            }).then(response => {
                                setData("sts", response.data.data);
                                resolve(response.data.data);
                            }).catch((e) => {
                                console.log(e)
                            });
                        }
                    }
                ).then((stsInfo) => {
                    /**
                     * stsInfo:{
                     *   accessKeyId: "STS.NSrSpja5mJZeGxPRU2NtWj59x"
                     *   accessKeySecret: "9f83946dbKpsC4Rgnntfe64FafXZ6iMjGvK832TtEdh7"
                     *   bucketName: "vtrt-vrlive"
                     *   endpoint: "http://oss-cn-beijing.aliyuncs.com"
                     *   expiration: "2020-04-17T15:07:31Z"
                     *   pathName: "t-t-0"
                     *   region: "oss-cn-beijing"
                     *   securityToken: ""CAISoQJ1q6Ft5B2yfSjIr5DHGMrejOpM/ZiORV7htlVnQvt7hfCSmjz2IHFLeHZoBesbtP0+nW5W7fsZlqxvDoMAHRQ96hvdHtIFnzm6aq/t5uZehN5t0e+zewW6Dxr8w7WMAYHQR8/cffGAck3NkjQJr5LxaTSlWS7TU/iOkoU1QdkLeQO6YDFaZrJRPRAwkNIGEnHTOP2xSEuI5FDdF011oAFxpHpi4KCkuK2m5wHZkUfxx51cxfP4KYP2aNJ3btUtEYWrwPZqM7Tb1C9O5l9Wsbsz1OsG20+e7ozNUgEPs0nZYrqIroM3FmIjOPhmQZwjhePniPh1ttbUk4nK0BtXNYlXKX+OG9z/mJefRbnwZ49nK+2mYG6m3t2RJj1rO7nr3LxwGoABlBJnCdLzVBX+eJQIxsoSTCdO5tby90xmeKYIZc7vbH3TmS9L70J15Lwkw3h+0SP79GEBJUBrfLWuiLfiY1QOFLyd7VCghkuSV6Jvp1MOck80CqLH/fEi6nBP0sk/xHdyIIcj5Mn7EAB0K4KYGZKYjHy5HSkM1kFp332hpYN97jE="
                     * }
                     */
                    return new Promise((resolve) => {
                        let fullName = stsInfo.pathName + '/' + filename;
                        new Client({
                            region: stsInfo.region,
                            accessKeyId: stsInfo.accessKeyId,
                            accessKeySecret: stsInfo.accessKeySecret,
                            bucket: stsInfo.bucketName,
                            stsToken: stsInfo.securityToken,
                        }).put(fullName, file, {
                            mime: file.type
                        }).then((result) => {
                            resolve(result.res.requestUrls[0])
                        });
                    });
                });
            })
        },

        // 获取AWS授权信息，并上传文件。 file：需要上传的文件  stsUrl：获取sts信息的地址   return:文件地址
        AWSFileUpload({state, commit}, {file, stsUrl}) {
            if (!file || !stsUrl) {
                return
            }
            return new Promise((resolve) => {
                let fileReader = new FileReader();
                let spark = new SparkMD5(); // 创建md5（基于SparkMD5）
                if (file.size > 1024 * 1024 * 10) {
                    let data = file.slice(0, 1024 * 1024 * 10); //将文件进行分块 file.slice(start,length)
                    fileReader.readAsBinaryString(data); //将文件读取为二进制码
                } else {
                    fileReader.readAsBinaryString(file);
                }

                fileReader.onload = (e) => {
                    spark.appendBinary(e.target.result);
                    let md5 = spark.end();
                    resolve(md5 + file.name);
                };
            }).then(filename => {
                return new Promise(resolve => {
                        let stsInfo = getData("sts");

                        // 过期时间大于三分钟时直接读取缓存的授权信息
                        if (stsInfo && new Date(stsInfo.expiration).getTime() - 3 * 60 * 1000 > Date.now()) {
                            resolve(stsInfo)
                        } else {
                            axios.request({
                                url: stsUrl,
                                method: 'get',
                            }).then(response => {
                                setData("sts", response.data.data);
                                resolve(response.data.data);
                            }).catch((e) => {
                                console.log(e)
                            });
                        }
                    }
                ).then((stsInfo) => {
                    /**
                     * stsInfo:{
                     *   accessKeyId: "STS.NSrSpja5mJZeGxPRU2NtWj59x"
                     *   accessKeySecret: "9f83946dbKpsC4Rgnntfe64FafXZ6iMjGvK832TtEdh7"
                     *   bucketName: "vtrt-vrlive"
                     *   endpoint: "http://oss-cn-beijing.aliyuncs.com"
                     *   expiration: "2020-04-17T15:07:31Z"
                     *   pathName: "t-t-0"
                     *   region: "oss-cn-beijing"
                     *   securityToken: ""CAISoQJ1q6Ft5B2yfSjIr5DHGMrejOpM/ZiORV7htlVnQvt7hfCSmjz2IHFLeHZoBesbtP0+nW5W7fsZlqxvDoMAHRQ96hvdHtIFnzm6aq/t5uZehN5t0e+zewW6Dxr8w7WMAYHQR8/cffGAck3NkjQJr5LxaTSlWS7TU/iOkoU1QdkLeQO6YDFaZrJRPRAwkNIGEnHTOP2xSEuI5FDdF011oAFxpHpi4KCkuK2m5wHZkUfxx51cxfP4KYP2aNJ3btUtEYWrwPZqM7Tb1C9O5l9Wsbsz1OsG20+e7ozNUgEPs0nZYrqIroM3FmIjOPhmQZwjhePniPh1ttbUk4nK0BtXNYlXKX+OG9z/mJefRbnwZ49nK+2mYG6m3t2RJj1rO7nr3LxwGoABlBJnCdLzVBX+eJQIxsoSTCdO5tby90xmeKYIZc7vbH3TmS9L70J15Lwkw3h+0SP79GEBJUBrfLWuiLfiY1QOFLyd7VCghkuSV6Jvp1MOck80CqLH/fEi6nBP0sk/xHdyIIcj5Mn7EAB0K4KYGZKYjHy5HSkM1kFp332hpYN97jE="
                     * }
                     */
                    return new Promise((resolve) => {
                        let param = {
                            region: stsInfo.region,
                            credentials: new aws.Credentials(stsInfo.accessKeyId, stsInfo.accessKeySecret, stsInfo.securityToken),
                        };
                        new AWS.S3(param).upload({
                            Bucket: stsInfo.bucketName,
                            Key: stsInfo.pathName + '/' + filename,
                            ContentType: file.type,
                            Body: file,
                            "Access-Control-Allow-Credentials": "*",
                            ACL: "public-read"
                        }).on("httpUploadProgress", function () {

                        }).send(async err => {
                            if (!err) {
                                resolve(fullName); // stsInfo.hostname +
                            } else {
                                console.log(err);
                            }
                        });
                    });
                });
            })
        }
    }
}

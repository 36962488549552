<template>
    <div :class="{'has-logo':showLogo}">
        <logo v-if="showLogo" :collapse="isCollapse"/>
        <el-scrollbar wrap-class="scrollbar-wrapper">
            <el-menu
                    :default-active="activeMenu"
                    :collapse="isCollapse"
                    :background-color="variables.menuBg"
                    :text-color="variables.menuText"
                    :unique-opened="true"
                    :active-text-color="variables.menuActiveText"
                    :collapse-transition="false"
                    mode="vertical">
                <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path"/>
            </el-menu>
        </el-scrollbar>
    </div>
</template>

<script>
    import Logo from './Logo'
    import SidebarItem from './SidebarItem'
    import variables from '@/styles/variables.scss'
    import {getUserInfo} from "@/utils/cookies";

    export default {
        components: {SidebarItem, Logo},
        computed: {
            sidebar() {
                return this.$store.state.app.sidebar
            },
            routes() {
                if (getUserInfo().permissions) {
                    return this.filterPermission(this.$router.options.routes)
                } else {
                    return this.$router.options.routes;
                }
            },
            activeMenu() {
                const route = this.$route;
                const {meta, path} = route;
                // if set path, the sidebar will highlight the path you set
                if (meta.activeMenu) {
                    return meta.activeMenu
                }
                return path
            },
            showLogo() {
                return this.$store.state.app.sidebarLogo
            },
            variables() {
                return variables;
            },
            isCollapse() {
                return !this.sidebar.opened
            }
        }, methods: {
            // 权限过滤
            filterPermission(routes) {
                return routes.filter(item => {
                    // 无权限检查：有权限定义，并且用户没有该权限

                    if (item.meta && item.meta.permission && !getUserInfo().permissions.includes(item.meta.permission)) {
                        return false;
                    } else { // 有权限，有子菜单时过滤子菜单
                        if (item.children) {
                            item.children = this.filterPermission(item.children);
                        }
                        return true;
                    }
                });
            }
        }
    }
</script>

import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router);

/**
 // path可以设置为网址
 path : http://haha.com

 // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 hidden: true // (默认 false)

 // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 redirect: 'noRedirect'

 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式
 // 只有一个时，会将那个子路由当做根路由显示在侧边栏
 // 若你想不管路由下面的 children 声明的个数都显示你的根路由你可以设置 alwaysShow: true，
 // 这样它就会忽略之前定义的规则，一直显示根路由
 alwaysShow: true

 name: 'router-name' //设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 meta: {
    permission: 'addBook' // 设置该路由进入的权限，支持多个权限叠加
    title: 'title' // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name' // 设置该路由的图标
    noCache: true // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    breadcrumb: false // 如果设置为false，则不会在breadcrumb面包屑中显示
    back:false // 是否显示返回箭头
    refresh: true // 是否显示刷新按钮。三级组件中有效，会在二级中显示刷新
    activeMenu: '/example/list'  // 高亮显示路径
    affix: true  // 将固定在tags-view中
 }
 */
export default [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path*',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    {
        path: '/login',
        hidden: true,
        component: () => import('@/views/Login')
    },
    {
        path: '/test',
        hidden: true,
        component: () => import('@/views/Test')
    }, {
        path: '/data',
        component: Layout,
        redirect: '/data/user',
        meta: {title: '统计管理', icon: 'data'},
        children: [{
            path: 'user',
            name: 'User',
            component: () => import('@/views/SubLayout'),
            redirect: '/data/user/index',
            meta: {title: '用户统计', icon: 'user',activeMenu:'/data/user'},
            children: [{
                path: 'index',
                name: 'userIndex',
                hidden: true,
                component: () => import('@/views/data/user/user'),
                meta: {title: '用户统计', icon: 'user',activeMenu:'/data/user'},
            }]
        }, {
            path: 'group',
            name: 'Group',
            component: () => import('@/views/SubLayout'),
            redirect: '/data/group/group',
            meta: {title: '班级统计', icon: 'group'},
            children: [{
                path: 'group',
                name: 'group_2',
                hidden: true,
                component: () => import('@/views/data/group/group'),
                meta: {title: '班级统计', icon: 'group',activeMenu:'/data/group'},
            }]
        }, {
            path: 'teacher',
            name: 'Teacher',
            component: () => import('@/views/SubLayout'),
            redirect: '/data/teacher/teacher',
            meta: {title: '教师活跃度统计', icon: 'teacher'},
            children: [{
                path: 'teacher',
                name: 'teacher_2',
                hidden: true,
                component: () => import('@/views/data/teacher/teacher'),
                meta: {title: '教师活跃度统计', icon: 'teacher',activeMenu:'/data/teacher'},
            }]
        }, {
            path: 'other',
            name: 'Other',
            component: () => import('@/views/SubLayout'),
            redirect: '/data/other/other',
            meta: {title: '其他统计', icon: 'other'},
            children: [{
                path: 'other',
                name: 'other_2',
                hidden: true,
                component: () => import('@/views/data/other/other'),
                meta: {title: '其他统计', icon: 'other',activeMenu:'/data/other'},
            }]
        }]
    }, {
        path: '/chinese',
        component: Layout,
        redirect: '/chinese/book',
        meta: {title: '语文管理', icon: 'chinese'},
        children: [{
            path: 'book',
            name: 'Book',
            component: () => import('@/views/SubLayout'),
            redirect: '/chinese/book/index',
            meta: {title: '教材管理', icon: 'book',activeMenu:'/chinese/book'},
            children: [{
                path: 'index',
                name: 'bookIndex',
                hidden: true,
                component: () => import('@/views/chinese/book/index'),
                meta: {title: '教材管理', icon: 'book',activeMenu:'/chinese/book'},
            }, {
                path: 'add',
                name: 'AddBook',
                hidden: true,
                component: () => import('@/views/chinese/book/add'),
                meta: {title: '添加教材', icon: 'book', back: true,activeMenu:'/chinese/book'},
            }, {
                path: 'update',
                name: 'Update',
                hidden: true,
                component: () => import('@/views/chinese/book/update'),
                meta: {title: '修改教材', icon: 'book', back: true,activeMenu:'/chinese/book'},
            }]
        }, {
            path: 'unit',
            name: 'Unit',
            component: () => import('@/views/SubLayout'),
            redirect: '/chinese/unit/unit',
            meta: {title: '单元管理', icon: 'unit'},
            children: [{
                path: 'unit',
                name: 'unit_2',
                hidden: true,
                component: () => import('@/views/chinese/unit/unit'),
                meta: {title: '单元管理', icon: 'unit', activeMenu:'/chinese/unit'},
            }, {
                path: 'add',
                name: 'unitAdd',
                hidden: true,
                component: () => import('@/views/chinese/unit/add'),
                meta: {title: '添加单元', icon: 'unit', back: true,activeMenu:'/chinese/unit'},
            }, {
                path: 'update',
                name: 'unitUpdate',
                hidden: true,
                component: () => import('@/views/chinese/unit/update'),
                meta: {title: '修改单元', icon: 'unit', back: true,activeMenu:'/chinese/unit'},
            }]
        }, {
            path: 'chapter',
            name: 'Chapter',
            component: () => import('@/views/SubLayout'),
            redirect: '/chinese/chapter/chapter',
            meta: {title: '章节管理', icon: 'chapter',activeMenu:'/chinese/chapter'},
            children: [{
                path: 'chapter',
                name: 'chapter_2',
                hidden: true,
                component: () => import('@/views/chinese/chapter/chapter'),
                meta: {title: '章节管理', icon: 'chapter',activeMenu:'/chinese/chapter'},
            }, {
                path: 'add',
                name: 'chapter_add',
                hidden: true,
                component: () => import('@/views/chinese/chapter/add'),
                meta: {title: '章节添加', icon: 'chapter',activeMenu:'/chinese/chapter'},
            }, {
                path: 'update',
                name: 'chapter_update',
                hidden: true,
                component: () => import('@/views/chinese/chapter/update'),
                meta: {title: '章节修改', icon: 'chapter',activeMenu:'/chinese/chapter'},
            }]
        }, {
            path: 'word',
            name: 'Word',
            component: () => import('@/views/SubLayout'),
            redirect: '/chinese/word/word',
            meta: {title: '字词管理', icon: 'word'},
            children: [{
                path: 'word',
                name: 'Word_2',
                hidden: true,
                component: () => import('@/views/chinese/word/word'),
                meta: {title: '字词管理', icon: 'word',activeMenu:'/chinese/word'},
            }, {
                path: 'add',
                name: 'Word_add',
                hidden: true,
                component: () => import('@/views/chinese/word/add'),
                meta: {title: '字词添加', icon: 'word', back: true,activeMenu:'/chinese/word'},
            }, {
                path: 'update',
                name: 'Word_update',
                hidden: true,
                component: () => import('@/views/chinese/word/update'),
                meta: {title: '字词修改', icon: 'word', back: true,activeMenu:'/chinese/word'},
            }]
        }]
    }, {
        path: '/school',
        component: Layout,
        redirect: '/school/school',
        meta: {title: '学校管理', icon: 'school'},
        children: [{
            path: 'school',
            name: 'Index',
            component: () => import('@/views/SubLayout'),
            redirect: '/school/school/index',
            meta: {title: '学校管理', icon: 'school',activeMenu:'/school/school'},
            children: [{
                path: 'index',
                name: 'Index_2',
                hidden: true,
                component: () => import('@/views/school/index/index'),
                meta: {title: '学校管理', icon: 'school',activeMenu:'/school/school'},
            }, {
                path: 'add',
                name: 'Add',
                hidden: true,
                component: () => import('@/views/school/index/add'),
                meta: {title: '学校管理', icon: 'school',activeMenu:'/school/school'},
            }]
        }]
    }, {
        path: '/manage',
        component: Layout,
        redirect: '/manage/manage',
        meta: {title: '管理员管理', icon: 'manage'},
        children: [{
            path: 'manage',
            name: 'Manage',
            component: () => import('@/views/SubLayout'),
            redirect: '/manage/manage/manage',
            meta: {title: '管理员管理', icon: 'user',activeMenu:'/manage/manage'},
            children: [{
                path: 'manage',
                name: 'manageIndex',
                hidden: true,
                component: () => import('@/views/manage/manage/manage'),
                meta: {title: '管理员管理', icon: 'manage1',activeMenu:'/manage/manage'},
            }, {
                path: 'add',
                name: 'manageAdd',
                hidden: true,
                component: () => import('@/views/manage/manage/add'),
                meta: {title: '管理员添加', icon: 'manage1',activeMenu:'/manage/manage'},
            }, {
                path: 'update',
                name: 'manageUpdate',
                hidden: true,
                component: () => import('@/views/manage/manage/update'),
                meta: {title: '管理员修改', icon: 'manage1', back: true,activeMenu:'/manage/manage'},
            }]
        }, {
            path: 'audit',
            name: 'Audit',
            component: () => import('@/views/SubLayout'),
            redirect: '/manage/audit/audit',
            meta: {title: '审核管理', icon: 'audit', permission: 'putAudit'},
            children: [{
                path: 'audit',
                name: 'Audit_2',
                hidden: true,
                component: () => import('@/views/manage/audit/audit'),
                meta: {title: '审核管理', icon: 'audit',activeMenu:'/manage/audit'},
            }, {
                path: 'detail',
                name: 'Detail',
                hidden: true,
                component: () => import('@/views/manage/audit/detail'),
                meta: {title: '审核管理', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'see',
                name: 'See',
                hidden: true,
                component: () => import('@/views/manage/audit/see'),
                meta: {title: '查看审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'unitDetail',
                name: 'UnitDetail',
                hidden: true,
                component: () => import('@/views/manage/audit/unitDetail'),
                meta: {title: '单元审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'chapterDetail',
                name: 'ChapterDetail',
                hidden: true,
                component: () => import('@/views/manage/audit/chapterDetail'),
                meta: {title: '章节审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'wordDetail',
                name: 'WordDetail',
                hidden: true,
                component: () => import('@/views/manage/audit/wordDetail'),
                meta: {title: '字词审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'excelDetail',
                name: 'excelDetail',
                hidden: true,
                component: () => import('@/views/manage/audit/excelDetail'),
                meta: {title: '教材excel审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'unitSee',
                name: 'unitSee',
                hidden: true,
                component: () => import('@/views/manage/audit/unitSee'),
                meta: {title: '查看单元审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'chapterSee',
                name: 'chapterSee',
                hidden: true,
                component: () => import('@/views/manage/audit/chapterSee'),
                meta: {title: '查看章节审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'wordSee',
                name: 'wordSee',
                hidden: true,
                component: () => import('@/views/manage/audit/wordSee'),
                meta: {title: '查看字词审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }, {
                path: 'excelSee',
                name: 'excelSee',
                hidden: true,
                component: () => import('@/views/manage/audit/excelSee'),
                meta: {title: '查看教材excel审核', icon: 'audit', back: true,activeMenu:'/manage/audit'},
            }]
        }]
    },
    // {
    //     path: '/file',
    //     component: Layout,
    //     redirect: '/file/AliOSS',
    //     meta: {title: '文件操作', icon: 'user'},
    //     children: [{
    //         path: 'AliOSS',
    //         name: 'AliOSS',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/file/AliOSS/index',
    //         meta: {title: '阿里云OSS文件上传', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'AliOSSIndex',
    //             hidden: true,
    //             component: () => import('@/views/fileUpload/AliOSSFileUpload'),
    //             meta: {title: '阿里云OSS文件上传', icon: 'user'},
    //         }]
    //     }]
    // },
    // {
    //     path: '/element',
    //     component: Layout,
    //     redirect: '/element/input',
    //     meta: {title: 'element组件', icon: 'user'},
    //     children: [{
    //         path: 'input',
    //         name: 'input',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/input/index',
    //         meta: {title: 'input组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'inputIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementInput'),
    //             meta: {title: 'input组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'table',
    //         name: 'table',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/table/index',
    //         meta: {title: 'table组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'tableIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementTable'),
    //             meta: {title: 'table组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'radio',
    //         name: 'radio',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/radio/index',
    //         meta: {title: 'radio组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'radioIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementRadio'),
    //             meta: {title: 'radio组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'checkbox',
    //         name: 'checkbox',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/checkbox/index',
    //         meta: {title: 'checkbox组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'checkboxIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementCheckBox'),
    //             meta: {title: 'checkbox组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'select',
    //         name: 'select',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/select/index',
    //         meta: {title: 'select组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'selectIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementSelect'),
    //             meta: {title: 'select组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'picker',
    //         name: 'picker',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/picker/index',
    //         meta: {title: 'picker组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'pickerIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementPicker'),
    //             meta: {title: 'picker组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'image',
    //         name: 'image',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/image/index',
    //         meta: {title: 'image组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'imageIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementImage'),
    //             meta: {title: 'image组件', icon: 'user'},
    //         }]
    //     }, {
    //         path: 'dialog',
    //         name: 'dialog',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/element/dialog/index',
    //         meta: {title: 'dialog组件', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'dialogIndex',
    //             hidden: true,
    //             component: () => import('@/views/element/elementDialog'),
    //             meta: {title: 'dialog组件', icon: 'user'},
    //         }]
    //     }]
    // }, {
    //     path: '/other',
    //     component: Layout,
    //     redirect: '/other/tinymce',
    //     meta: {title: '其他组件', icon: 'user'},
    //     children: [{
    //         path: 'tinymce',
    //         name: 'tinymce',
    //         component: () => import('@/views/SubLayout'),
    //         redirect: '/other/tinymce/index',
    //         meta: {title: 'tinymce富文本编辑器', icon: 'user'},
    //         children: [{
    //             path: 'index',
    //             name: 'tinymceIndex',
    //             hidden: true,
    //             component: () => import('@/views/other/tinymceRichText'),
    //             meta: {title: 'tinymce富文本编辑器', icon: 'user'},
    //         }]
    //     }]
    // },
    // 404 page must be placed at the end !!!
    {
        path: '*',
        redirect: '/data',
        hidden: true
    }
]

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import './plugins/element.js'
import VeLine from 'v-charts/lib/line.common'
import Histogram from 'v-charts/lib/histogram.common'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import '@/styles/index.scss' // global css

import '@/icons' // icon
Vue.component('ve-line', VeLine)
Vue.component('ve-histogram', Histogram)

Vue.config.productionTip = false;

// 实际打包时应该不引入mock !!! 这里不能使用 import 代替 require
// if (process.env.NODE_ENV !== 'production') require('@/mock');

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

import Cookies from 'js-cookie'
import config from "@/config";

const UserInfoKey = 'userInfo';

let info = null;

export function checkPermission(permission) {
    return getUserInfo().permissions && getUserInfo().permissions.includes(permission);
}

export function getUserInfo() {
    return info || JSON.parse(Cookies.get(UserInfoKey) || "{}");
}

export function setUserInfo(userInfo) {
    info = userInfo;
    return Cookies.set(UserInfoKey, JSON.stringify(userInfo), {expires: config.cookieExpires || 1});
}

export function removeUserInfo() {
    info = null;
    return Cookies.remove(UserInfoKey);
}

export function setData(key, value) {
    Cookies.set(key, JSON.stringify(value));
}

export function getData(key) {
    return Cookies.get(key) && JSON.parse(Cookies.get(key));
}

export function removeData(key) {
    Cookies.remove(key);
}


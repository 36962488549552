<template>
    <el-breadcrumb class="app-breadcrumb" separator="/">
        <transition-group name="breadcrumb">
            <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
                <span v-if="item.redirect==='noRedirect'||index===levelList.length-1 || index === 0"
                      class="no-redirect">{{ item.meta.title }}</span>
                <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
            </el-breadcrumb-item>
        </transition-group>
    </el-breadcrumb>
</template>

<script>

    export default {
        data() {
            return {
                levelList: [],
            }
        },
        watch: {
            $route() {
                this.getBreadcrumb()
            }
        },
        created() {
            this.getBreadcrumb()
        },
        methods: {
            getBreadcrumb() {
                // let lastPath = this.$route.matched[this.$route.matched.length - 1].path;
                // let paths = lastPath.split('/');
                // this.levelList = [];
                // let node = this.dictionary;
                // let path = '';
                // for (let i = 1; i < 5 && paths[i]; i++) {
                //     node = node[paths[i]];
                //     if (node && node.title) {
                //         path += '/' + paths[i];
                //         this.levelList.push({title: node.title, path})
                //     } else {
                //         break;
                //     }
                // }
                // only show routes with meta.title
                let matched = this.$route.matched.filter(item => item.meta && item.meta.title);

                // const first = matched[0];
                // if (!this.isHome(first)) {
                //   matched = [{ path: '/home', meta: { title: '首页' }}].concat(matched)
                // }

                this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
                if (this.levelList.length > 2) {
                    this.levelList.length = 2;
                }
            },
            isHome(route) {
                const name = route && route.name;
                if (!name) {
                    return false
                }
                return name.trim().toLocaleLowerCase() === 'Home'.toLocaleLowerCase()
            },
            handleLink(item) {
                this.$router.push(item.path);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .app-breadcrumb.el-breadcrumb {
        display: inline-block;
        font-size: 14px;
        line-height: 50px;
        margin-left: 8px;

        .no-redirect {
            color: #97a8be;
            cursor: text;
        }
    }
</style>
